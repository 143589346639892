import React, { useState } from 'react';
import Header from './components/Header';
import './App.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App: React.FC = () => {
  const [showProductManager, setShowProductManager] = useState(false);
  const [showJrProductManager, setShowJrProductManager] = useState(false);
  const [showSeniorSpecialist, setShowSeniorSpecialist] = useState(false);
  const [showFullStack, setShowFullStack] = useState(false);
  const [showFullstack, setShowFullstack] = useState(false);
  const [showBlockchain, setShowBlockchain] = useState(false);
  const [showDatabase, setShowDatabase] = useState(false);
  const [showAI, setShowAI] = useState(false);
  const [showCloud, setshowCloud] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [showProjectManagement, setShowProjectManagement] = useState(false);
  const [showGalverse, setShowGalverse] = useState(false);
  const [showHunnysCloset, setShowHunnysCloset] = useState(false);
  const [showThirdweb, setShowThirdweb] = useState(false);
  const [showCoinbase, setShowCoinbase] = useState(false);
  const [showFullStackDev, setShowFullStackDev] = useState(false);
  const [showEcommerceDev, setShowEcommerceDev] = useState(false);
  const [showWorkflowAutomation, setShowWorkflowAutomation] = useState(false);
  const [showBlockchainDev, setShowBlockchainDev] = useState(false);
  const [showCloudServices, setShowCloudServices] = useState(false);

  const toggleSection = (section: string) => {
    if (section === 'ProductManager') {
      setShowProductManager(!showProductManager);
    } else if (section === 'JrProductManager') {
      setShowJrProductManager(!showJrProductManager);
    } else if (section === 'SeniorSpecialist') {
      setShowSeniorSpecialist(!showSeniorSpecialist);
    } else if (section === 'FullStack') {
      setShowFullStack(!showFullStack);
    } else if (section === 'Fullstack') {
      setShowFullstack(!showFullstack);
    } else if (section === 'Blockchain') {
      setShowBlockchain(!showBlockchain);
    } else if (section === 'Database') {
      setShowDatabase(!showDatabase);
    } else if (section === 'AI') {
      setShowAI(!showAI);
    } else if (section === 'Tools') {
      setshowCloud(!showCloud);
    } else if (section === 'Analytics') {
      setShowAnalytics(!showAnalytics);
    } else if (section === 'ProjectManagement') {
      setShowProjectManagement(!showProjectManagement);
    } else if (section === 'Galverse') {
      setShowGalverse(!showGalverse);
    } else if (section === 'HunnysCloset') {
      setShowHunnysCloset(!showHunnysCloset);
    } else if (section === 'Thirdweb') {
      setShowThirdweb(!showThirdweb);
    } else if (section === 'Coinbase') {
      setShowCoinbase(!showCoinbase);
    } else if (section === 'FullStackDev') {
      setShowFullStackDev(!showFullStackDev);
    } else if (section === 'EcommerceDev') {
      setShowEcommerceDev(!showEcommerceDev);
    } else if (section === 'WorkflowAutomation') {
      setShowWorkflowAutomation(!showWorkflowAutomation);
    } else if (section === 'BlockchainDev') {
      setShowBlockchainDev(!showBlockchainDev);
    } else if (section === 'CloudServices') {
      setShowCloudServices(!showCloudServices);
    }
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    adaptiveHeight: true,
  };

  
  return (
    <div className="App bg-gray-100 min-h-screen">
      <Header />
      <main className="container mx-auto p-8">
      <section id="intro" className="flex flex-col md:flex-row items-center my-16 bg-[#f0f8ff] p-8 shadow-lg rounded-lg">
        <div className="w-full md:w-1/3 flex flex-col items-center mb-8 md:mb-0">
          <img src="/images/portfolioPic.png" alt="Chris Fox" className="w-48 h-48 rounded-full shadow-md mb-4" />
          <h1 className="text-5xl font-bold text-primary mb-2">Chris Fox</h1>
          <p className="text-lg text-black">chris.b.fox1@gmail.com</p>
        </div>
        <div className="w-full md:w-2/3 text-left md:pl-8">
          <p className="text-2xl text-black font-semibold mb-4">A dynamic web developer with a talent for learning and adapting, I am committed to delivering innovative solutions that meet the evolving needs of businesses. My strong interpersonal skills and diverse experience enable me to build effective partnerships and drive success.</p>
        </div>
      </section>
      <section id="portfolio" className="my-16">
          <h2 className="text-4xl font-semibold text-primary mb-8 text-center">Skills & Portfolio</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="skill contentblock">
              <h3 className="text-3xl font-semibold text-primary text-center">Technical Skills</h3>
              <p className="text-black mt-2 text-center">Specifics on particularly honed areas of technical prowess in both traditional full stack development as well as Blockchain development.</p>
              <h4 className="collapsible subheading" onClick={() => toggleSection('Fullstack')}>
                Fullstack Development
                <span className="ml-2">{showFullstack ? '▲' : '▼'}</span>
              </h4>
              {showFullstack && (
                <ul className="list-disc list-inside text-black mt-2">
                  <li>JavaScript</li>
                  <li>React</li>
                  <li>Node.js</li>
                  <li>TypeScript</li>
                  <li>Tailwind</li>
                  <li>Python</li>
                </ul>
              )}
              <h4 className="collapsible subheading" onClick={() => toggleSection('Blockchain')}>
                Blockchain Development
                <span className="ml-2">{showBlockchain ? '▲' : '▼'}</span>
              </h4>
              {showBlockchain && (
                <ul className="list-disc list-inside text-black mt-2">
                  <li>Solidity</li>
                  <li>Ethereum</li>
                  <li>Smart Contracts</li>
                </ul>
              )}
              <h4 className="collapsible subheading" onClick={() => toggleSection('Database')}>
                Database
                <span className="ml-2">{showDatabase ? '▲' : '▼'}</span>
              </h4>
              {showDatabase && (
                <ul className="list-disc list-inside text-black mt-2">
                  <li>SQL</li>
                  <li>Redis</li>
                  <li>MongoDB</li>
                </ul>
              )}
              <h4 className="collapsible subheading" onClick={() => toggleSection('AI')}>
                AI
                <span className="ml-2">{showAI ? '▲' : '▼'}</span>
              </h4>
              {showAI && (
                <ul className="list-disc list-inside text-black mt-2">
                  <li>OpenAI suite</li>
                  <li>HuggingFace</li>
                  <li>MidJourney</li>
                  <li>Descript</li>
                  <li>Corresponding APIs</li>
                </ul>
              )}
              <h4 className="collapsible subheading" onClick={() => toggleSection('Tools')}>
                Cloud
                <span className="ml-2">{showCloud ? '▲' : '▼'}</span>
              </h4>
              {showCloud && (
                <ul className="list-disc list-inside text-black mt-2">
                  <li>AWS</li>
                  <li>Docker</li>
                  <li>CI/CD</li>
                  <li>Heroku</li>
                </ul>
              )}
              <h4 className="collapsible subheading" onClick={() => toggleSection('ProjectManagement')}>
                Project Management
                <span className="ml-2">{showProjectManagement ? '▲' : '▼'}</span>
              </h4>
              {showProjectManagement && (
                <ul className="list-disc list-inside text-black mt-2">
                  <li>Jira</li>
                  <li>Confluence</li>
                  <li>Trello</li>
                </ul>
              )}
            </div><section id="visualportfolio" className="">
            <div className="skill contentblock">
              <h3 className="text-3xl font-semibold text-primary text-center">Recent Portfolio</h3>
              <Slider {...settings}>
            <div className="contentblock">
              <p className="text-black mt-2 text-center">Participated in the inaugural rollout of embedded wallets for Coinbase in collaboration with Coinbase developer & product marketing teams.</p>
              <img src="/images/portfolioCoinbase.png" alt="Coinbase Embedded Wallets" className="w-full h-auto rounded-lg shadow-md mt-4" />
              <h3 className="text-3xl font-semibold text-primary text-center mt-4">Coinbase Embedded Wallets</h3>
            </div>
            <div className="contentblock">
              <p className="text-black mt-2 text-center">Developed & co-founded the thirdweb YouTube channel, assisted in creating a series of educational videos & tutorials for thirdweb, establishing their social presence.</p>
              <img src="/images/portfolioThirdweb.png" alt="thirdweb YouTube Channel" className="w-full h-auto rounded-lg shadow-md mt-4" />
              <h3 className="text-3xl font-semibold text-primary text-center mt-4">thirdweb YouTube Channel</h3>
            </div>
            <div className="contentblock">
              <p className="text-black mt-2 text-center">Crafted a highly interactive, immersive, user-friendly Web3 gaming platform for Hunnys.</p>
              <img src="/images/portfolioHunnysCloset.png" alt="Hunnys Closet" className="w-full h-auto rounded-lg shadow-md mt-4" />
              <h3 className="text-3xl font-semibold text-primary text-center mt-4">Hunnys Closet</h3>
            </div>
            <div className="contentblock">
              <p className="text-black mt-2 text-center">Provided the Galverse team with their very own comprehensive, fully-branded NFT marketplace, allowing users to trade and manage NFTs seamlessly.</p>
              <img src="/images/portfolioGalverse.png" alt="Galverse NFT Marketplace" className="w-full h-auto rounded-lg shadow-md mt-4" />
              <h3 className="text-3xl font-semibold text-primary text-center mt-4">Galverse NFT Marketplace</h3>
            </div>
          </Slider>
            </div>
          </section>
          </div>
        </section>
        <section id="experience" className="my-16">
          <h2 className="text-4xl font-semibold text-primary mb-8 text-center">Experience</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="experience-item contentblock">
  <h3 className="text-3xl font-semibold text-center text-primary">Independent Full Stack / Blockchain Development</h3>
  <p className="text-black mt-2 text-center italic">November 2021 - Present</p>
  <p className="text-black mt-2 text-center">Building dozens of meaningful, lasting relationships with small-to-medium sized business owners and brands, leading to my being contracted for a variety of tasks.</p>
    <div>
      <h4 className="collapsible subheading" onClick={() => toggleSection('EcommerceDev')}>
        E-commerce Websites
        <span className="ml-2">{showEcommerceDev ? '▲' : '▼'}</span>
      </h4>
      {showEcommerceDev && (
        <ul className="list-disc list-inside text-black mt-2">
          <li>Developing and deploying scalable e-commerce platforms using React, Node.js, and TypeScript.</li>
          <li>Integrating secure payment gateways, optimized for high traffic.</li>
        </ul>
      )}
      <h4 className="collapsible subheading" onClick={() => toggleSection('WorkflowAutomation')}>
        Workflow Automation
        <span className="ml-2">{showWorkflowAutomation ? '▲' : '▼'}</span>
      </h4>
      {showWorkflowAutomation && (
        <ul className="list-disc list-inside text-black mt-2">
          <li>Regularly developing automation scripts using Python to streamline repetitive tasks, increasing efficiency for busy business owners.</li>
          <li>Integrating AI tools like OpenAI and HuggingFace to enhance business operations as well as provide entertainment for end users.</li>
        </ul>
      )}
      <h4 className="collapsible subheading" onClick={() => toggleSection('BlockchainDev')}>
        Blockchain Development & Education
        <span className="ml-2">{showBlockchainDev ? '▲' : '▼'}</span>
      </h4>
      {showBlockchainDev && (
        <ul className="list-disc list-inside text-black mt-2">
          <li>Deploying smart contracts using Solidity on the Ethereum network.</li>
          <li>Creating and managing NFT marketplaces, ensuring secure and efficient transactions.</li>
          <li>Publishing multiple YouTube videos and multi-course educational packages, for developers and users.</li>
        </ul>
      )}
      
      <h4 className="collapsible subheading" onClick={() => toggleSection('CloudServices')}>
        Cloud Services
        <span className="ml-2">{showCloudServices ? '▲' : '▼'}</span>
      </h4>
      {showCloudServices && (
        <ul className="list-disc list-inside text-black mt-2">
          <li>Utilizing AWS services such as Elastic Beanstalk, S3, RDS, and Lambda for deploying and managing web applications.</li>
          <li>Connecting frontend/client services (Vercel, WordPress) with backend node servers (Heroku, AWS, Docker) to allow clients to securely and efficently make API calls between their service and others.</li>
          <li>Implementing monitoring and logging using CloudWatch or Papertrail to ensure high availability and performance.</li>
        </ul>
      )}
    </div>
</div>

            <div className="experience-item contentblock">
              <h3 className="text-3xl font-semibold text-center text-primary">AWeber</h3>
              <p className="text-black mt-2 text-center italic">September 2014 - November 2021</p>
              <p className="text-black mt-2 text-center">AWeber is a digital marketing platform that helps connect small business owners & entrepreneurs with their audiences.</p>

              <h4 className="collapsible" onClick={() => toggleSection('ProductManager')}>
                Product Manager
                <span className="ml-2">{showProductManager ? '▲' : '▼'}</span>
              </h4>
              {showProductManager && (
                <div>
                  <p className="text-black mt-2 text-center italic">Chalfont, PA - February 2021 - November 2021</p>
                  <ul className="list-disc list-inside text-black mt-2">
                    <li>Developed my team’s 2021-2022 product roadmap in coordination with the larger AWeber team.</li>
                    <li>Required extensive competitor analysis & market research to help develop the roadmap.</li>
                    <li>Collaborated with the Product & Engineering teams to establish what is considered MVP of a given product vs. the product’s ideal state.</li>
                    <li>Reimagined the entire onboarding flow in our web app for new users, significantly increasing BI capabilities for the AWeber team & improving the percentage of new users engaging with key AWeber features.</li>
                    <li>Developed front end changes on our public website to update our checkout flow for new users, leading to increases in Signup-to-Login conversion.</li>
                  </ul>
                </div>
              )}

              <h4 className="collapsible" onClick={() => toggleSection('JrProductManager')}>
                Jr. Product Manager
                <span className="ml-2">{showJrProductManager ? '▲' : '▼'}</span>
              </h4>
              {showJrProductManager && (
                <div>
                  <p className="text-black mt-2 text-center italic">Chalfont, PA - Feb 2020 - Feb 2021</p>
                  <ul className="list-disc list-inside text-black mt-2">
                    <li>Launched AWeber’s Freemium tier (July 2020), taking AWeber from a Paid-only pricing model to a Freemium pricing model.</li>
                    <li>Identifying opportunities through quantitative and qualitative data, along with quick implementation of said opportunities, has lead to consistently increasing our Daily Average Upgrade numbers from July 2020-February 2021 by 40% over time.</li>
                    <li>Learned how to manage the life cycle of a product from strategic planning, to tactical implementation, to launch, to feedback/data analysis, and to further iteration when viable.</li>
                  </ul>
                </div>
              )}

              <h4 className="collapsible" onClick={() => toggleSection('SeniorSpecialist')}>
                Senior Customer Solutions Specialist
                <span className="ml-2">{showSeniorSpecialist ? '▲' : '▼'}</span>
              </h4>
              {showSeniorSpecialist && (
                <div>
                  <p className="text-black mt-2 text-center italic">Chalfont, PA - May 2017 - Feb 2020</p>
                  <ul className="list-disc list-inside text-black mt-2">
                    <li>Served as a liaison to multiple Product teams to strengthen the integration of Customer Solutions & Product.</li>
                    <li>Represented multiple Product teams at the weekly Customer Solutions briefing, sharing relevant product information to promote buy-in of features, ensure superior customer communication, & solicit perspective from the Customer Solutions team.</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </section>
        
        <section id="education" className="my-16">
          <h2 className="text-4xl font-semibold text-primary mb-8 text-center">Education</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="education-item contentblock">
              <h3 className="text-3xl font-semibold text-primary">Ursinus College</h3>
              <p className="text-black mt-2">Bachelor of Arts Degree in Media & Communications</p>
              <p className="text-black mt-2">September 2011 - May 2013</p>
            </div>
            <div className="education-item contentblock">
              <h3 className="text-3xl font-semibold text-primary">Montgomery County Community College</h3>
              <p className="text-black mt-2">Associate’s Degree in Liberal Arts</p>
              <p className="text-black mt-2">September 2009 - May 2011</p>
            </div>
          </div>
        </section>
        <section id="contact" className="my-16">
          <h2 className="text-4xl font-semibold text-primary mb-8 text-center">Contact</h2>
          <div className="text-black mt-2 contentblock !text-center">
            <p className='font-bold'>Chris Fox</p>
            <p>235 Morwood Road, Telford, PA 18969</p>
            <p>215-872-8102</p>
            <p>chris.b.fox1@gmail.com</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default App;