import React, { useState } from 'react';

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-primary text-white py-6 shadow-md sticky top-0 z-50">
      <div className="container flex justify-between items-center">
        <a href="/#" className="text-4xl font-bold logo relative left-[30px]">CF</a>
        <nav className="hidden sm:block">
          <ul className="flex space-x-8 text-2xl">
            <li><a href="#portfolio" className="font-bold">Portfolio</a></li>
            <li><a href="#experience" className="font-bold">Experience</a></li>
            <li><a href="#education" className="font-bold">Education</a></li>
            <li><a href="#contact" className="font-bold">Contact</a></li>
          </ul>
        </nav>
        <button
          className="sm:hidden flex items-center px-3 py-2 border rounded text-white border-white hover:text-primary hover:border-primary ml-auto relative right-[30px]"
          onClick={toggleMenu}
        >
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      {menuOpen && (
        <nav className="sm:hidden">
          <ul className="flex flex-col items-center space-y-4 mt-4 text-2xl">
            <li><a href="#portfolio" className="font-bold" onClick={toggleMenu}>Portfolio</a></li>
            <li><a href="#experience" className="font-bold" onClick={toggleMenu}>Experience</a></li>
            <li><a href="#education" className="font-bold" onClick={toggleMenu}>Education</a></li>
            <li><a href="#contact" className="font-bold" onClick={toggleMenu}>Contact</a></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
